import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow ,atomDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { blogPosts } from './blogPosts';
import { Calendar, User, ArrowLeft, Copy, Check } from 'lucide-react';

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));
  const [copiedCode, setCopiedCode] = useState(null);

  if (!post) {
    return (
      <div className="text-center py-8 sm:py-12">
        <h2 className="text-xl sm:text-2xl font-semibold text-neutral-700 dark:text-neutral-300">Post not found</h2>
        <Link to="/" className="mt-4 inline-flex items-center text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 transition-colors duration-200">
          <ArrowLeft size={16} className="mr-2" />
          Back to Blog
        </Link>
      </div>
    );
  }

  const copyToClipboard = (code, index) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(index);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-6xl mx-1 px-1 sm:px-6 lg:px-8 py-6 sm:py-8 md:py-12"
    >
      <Link to="/" className="inline-flex items-center text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 transition-colors duration-200 mb-4 sm:mb-6">
        <ArrowLeft size={16} className="mr-2" />
        Back to Blog
      </Link>
      <article className="dark:bg-neutral-800 rounded-lg p-2 sm:p-6 md:p-8">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2 sm:mb-4 text-primary-600 dark:text-primary-400">{post.title}</h1>
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 sm:mb-6 text-xs sm:text-sm text-neutral-500 dark:text-neutral-400">
          <div className="flex items-center space-x-2 mb-2 sm:mb-0">
            <User size={14} />
            <span>{post.author}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Calendar size={14} />
            <span>{post.date}</span>
          </div>
        </div>
        <div>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({ node, ...props }) => <h1 className="text-2xl sm:text-3xl font-bold mt-6 sm:mt-8 mb-3 sm:mb-4 text-neutral-900 dark:text-neutral-100" {...props} />,
              h2: ({ node, ...props }) => <h2 className="text-xl sm:text-2xl font-semibold mt-5 sm:mt-6 mb-2 sm:mb-3 text-neutral-800 dark:text-neutral-200" {...props} />,
              h3: ({ node, ...props }) => <h3 className="text-lg sm:text-xl font-semibold mt-4 sm:mt-5 mb-2 text-neutral-800 dark:text-neutral-200" {...props} />,
              p: ({ node, ...props }) => <p className="mb-3 sm:mb-4 text-sm sm:text-base text-neutral-700 dark:text-neutral-300" {...props} />,
              ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-3 sm:mb-4 text-sm sm:text-base text-neutral-700 dark:text-neutral-300" {...props} />,
              ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-3 sm:mb-4 text-sm sm:text-base text-neutral-700 dark:text-neutral-300" {...props} />,
              li: ({ node, ...props }) => <li className="mb-1 sm:mb-2" {...props} />,
              a: ({ node, ...props }) => <a className="text-primary-600 dark:text-primary-400 hover:underline" {...props} />,
              blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-primary-500 pl-3 sm:pl-4 italic my-3 sm:my-4 text-sm sm:text-base text-neutral-600 dark:text-neutral-400" {...props} />,
              img: ({ node, ...props }) => (
                <img
                  {...props}
                  className="rounded-lg shadow-md my-3 sm:my-4 max-w-full h-auto"
                  alt={props.alt || "Blog post image"}
                />
              ),
              table: ({ node, ...props }) => (
                <div className="overflow-x-auto my-3 sm:my-4">
                  <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700 text-sm sm:text-base" {...props} />
                </div>
              ),
              th: ({ node, ...props }) => <th className="px-4 sm:px-6 py-2 sm:py-3 bg-neutral-50 dark:bg-neutral-800 text-left text-xs font-medium text-neutral-500 dark:text-neutral-400 uppercase tracking-wider" {...props} />,
              td: ({ node, ...props }) => <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-nowrap text-sm text-neutral-700 dark:text-neutral-300" {...props} />,
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                const language = match ? match[1] : '';
                return !inline ? (
                  <div className="relative">
                    <div className="absolute top-0 right-0 mt-2 mr-2 flex items-center space-x-2">
                      <span className="text-xs font-mono bg-primary-100 dark:bg-primary-900 text-primary-700 dark:text-primary-300 px-2 py-1 rounded">{language}</span>
                      <button
                        onClick={() => copyToClipboard(String(children), node.position?.start.line)}
                        className="text-neutral-400 hover:text-neutral-600 dark:text-neutral-500 dark:hover:text-neutral-300 transition-colors duration-200"
                      >
                        {copiedCode === node.position?.start.line ? (
                          <Check size={14} className="text-green-500" />
                        ) : (
                          <Copy size={14} />
                        )}
                      </button>
                    </div>
                    <SyntaxHighlighter
                      style={atomDark }
                      language={language}
                      PreTag="div"
                      className="rounded-md my-3 sm:my-4 pt-8 text-sm sm:text-base"
                      {...props}
                    >
                      {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                  </div>
                ) : (
                  <code className="bg-neutral-100 dark:bg-neutral-700 rounded-md px-1 py-0.5 text-sm text-neutral-800 dark:text-neutral-200" {...props}>
                    {children}
                  </code>
                );
              },
              iframe: ({ node, ...props }) => (
                <div className="relative aspect-w-16 aspect-h-9 my-4 sm:my-6">
                  <iframe
                    {...props}
                    className="absolute inset-0 w-full h-full rounded-lg shadow-lg"
                  />
                </div>
              ),
            }}
          >
            {post.content}
          </ReactMarkdown>
        </div>
      </article>
    </motion.div>
  );
};

export default BlogPost;
