import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { blogPosts } from './blogPosts';

const BlogCreator = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    excerpt: '',
    author: '',
    content: '',
    tags: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = {
      id: blogPosts.length + 1,
      ...formData,
      date: new Date().toISOString().split('T')[0],
      tags: formData.tags.split(',').map(tag => tag.trim()),
    };
    blogPosts.push(newPost);
    navigate(`/post/${newPost.id}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 md:py-12"
    >
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-primary-600 dark:text-primary-400">Create New Blog Post</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="title" className="block mb-1 text-sm font-medium text-neutral-700 dark:text-neutral-300">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            required
            value={formData.title}
            onChange={handleChange}
            className="mt-1 p-1 block w-full h-10 rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
          />
        </div>
        <div>
          <label htmlFor="excerpt" className="block mb-1 text-sm font-medium text-neutral-700 dark:text-neutral-300">Excerpt</label>
          <textarea
            id="excerpt"
            name="excerpt"
            rows="3"
            required
            value={formData.excerpt}
            onChange={handleChange}
            className="mt-1 p-2 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
          ></textarea>
        </div>
        <div>
          <label htmlFor="author" className="block mb-1 text-sm font-medium text-neutral-700 dark:text-neutral-300">Author</label>
          <input
            type="text"
            id="author"
            name="author"
            required
            value={formData.author}
            onChange={handleChange}
            className="mt-1 p-1 block w-full h-10 rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
          />
        </div>
        <div>
          <label htmlFor="content" className="block mb-1 text-sm font-medium text-neutral-700 dark:text-neutral-300">Content (Markdown)</label>
          <textarea
            id="content"
            name="content"
            rows="10"
            required
            value={formData.content}
            onChange={handleChange}
            className="mt-1 p-2 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
          ></textarea>
        </div>
        <div>
          <label htmlFor="tags" className="block mb-1 text-sm font-medium text-neutral-700 dark:text-neutral-300">Tags (comma-separated)</label>
          <input
            type="text"
            id="tags"
            name="tags"
            required
            value={formData.tags}
            onChange={handleChange}
            className="mt-1 p-1 block w-full h-10 rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-100"
          />
        </div>
        <div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-primary-500 dark:hover:bg-primary-600"
          >
            Create Post
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default BlogCreator;
