import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { blogPosts } from './blogPosts';
import { Calendar, User, ArrowRight } from 'lucide-react';

const BlogList = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-12"
    >
      <h1 className="text-4xl font-bold ml-3 text-primary-600 dark:text-primary-400">Latest Posts</h1>
      <div className="min-h-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogPosts.map((post) => (
          <motion.div
            key={post.id}
            className="bg-white m-2 dark:bg-neutral-800 h-fit rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105"
            whileHover={{ y: -5 }}
          >
            <Link to={`/post/${post.id}`}>
              <div className="p-6 space-y-4">
                <h2 className="text-2xl font-semibold text-primary-600 dark:text-primary-400">{post.title}</h2>
                <p className="text-neutral-600 dark:text-neutral-300">{post.excerpt}</p>
                <div className="flex justify-between items-center text-sm text-neutral-500 dark:text-neutral-400">
                  <div className="flex items-center space-x-2">
                    <User size={16} />
                    <span>{post.author}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Calendar size={16} />
                    <span>{post.date}</span>
                  </div>
                </div>
                <div className="flex items-center text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 transition-colors duration-200">
                  <span className="mr-2">Read more</span>
                  <ArrowRight size={16} />
                </div>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default BlogList;

