import React, { useState } from "react";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Edit, Trash2, Copy, Check, Code } from "lucide-react";


const RenderPost = ({ post, onEdit, onDelete }) => {
  const [copiedCode, setCopiedCode] = useState(null);
  const [hoveredLine, setHoveredLine] = useState(null);
  const [htmlCopied, setHtmlCopied] = useState(false);

  const copyToClipboard = (code, index) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(index);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  const copyHtmlToClipboard = () => {
    const element = document.getElementById("rendered-html");
    if (element) {
      const html = element.innerHTML;
      navigator.clipboard.writeText(html).then(() => {
        setHtmlCopied(true);
        setTimeout(() => setHtmlCopied(false), 2000);
      });
    }
  };

  const wrapWithEditDelete = (Element, props) => {
    const { node, ...rest } = props;
    const startLine = node.position?.start.line;
    const endLine = node.position?.end.line;
    const isMultiLine = startLine !== endLine;

    return (
      <div
        className="relative group"
        onMouseEnter={() => setHoveredLine(startLine)}
        onMouseLeave={() => setHoveredLine(null)}
      >
        <Element {...rest} />
        {hoveredLine === startLine && (
          <div className="absolute top-0 right-0 flex space-x-2 bg-white dark:bg-gray-800 rounded-md shadow-sm">
            <button
              onClick={() =>
                onEdit(startLine - 1, isMultiLine ? endLine - 1 : undefined)
              }
              className="p-1 text-primary-500 hover:text-primary-600 transition-colors"
            >
              <Edit size={16} />
            </button>
            <button
              onClick={() =>
                onDelete(startLine - 1, isMultiLine ? endLine - 1 : undefined)
              }
              className="p-1 text-red-500 hover:text-red-600 transition-colors"
            >
              <Trash2 size={16} />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 md:py-12"
    >
      <div>
        <div className="flex justify-end mb-4">
          <button
            onClick={copyHtmlToClipboard}
            title="Copy HTML"
            className="flex items-center  -mt-10 mr-5 text-neutral-400 hover:text-neutral-600 dark:text-neutral-500 dark:hover:text-neutral-300 transition-colors duration-200"
          >
            {htmlCopied ? (
              <Check size={20} className="text-green-500 mr-2" />
            ) : (
              <Code size={20} className="mr-2" />
            )}
          </button>
        </div>
        <div id="rendered-html">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <h1 className="text-2xl sm:text-3xl font-bold mt-6 sm:mt-8 mb-3 sm:mb-4 text-neutral-900 dark:text-neutral-100" {...rest}>{children}</h1>
              ), props),
              h2: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <h2 className="text-xl sm:text-2xl font-semibold mt-5 sm:mt-6 mb-2 sm:mb-3 text-neutral-800 dark:text-neutral-200" {...rest}>{children}</h2>
              ), props),
              h3: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <h3 className="text-lg sm:text-xl font-semibold mt-4 sm:mt-5 mb-2 text-neutral-800 dark:text-neutral-200" {...rest}>{children}</h3>
              ), props),
              p: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <p className="mb-3 sm:mb-4 text-sm sm:text-base text-neutral-700 dark:text-neutral-300" {...rest}>{children}</p>
              ), props),
              ul: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <ul className="list-disc list-outside pl-6 space-y-2 mb-4 text-neutral-700 dark:text-neutral-300" {...rest}>
                  {children}
                </ul>
              ), props),
              ol: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <ol className="list-decimal list-outside pl-6 space-y-2 mb-4 text-neutral-700 dark:text-neutral-300" {...rest}>
                  {children}
                </ol>
              ), props),
              li: (props) => (
                <li className="pl-2 text-sm sm:text-base" {...props} />
              ),
              a: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <a className="text-primary-600 dark:text-primary-400 hover:underline" {...rest}>{children}</a>
              ), props),
              blockquote: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <blockquote className="border-l-4 border-primary-500 pl-3 sm:pl-4 italic my-3 sm:my-4 text-sm sm:text-base text-neutral-600 dark:text-neutral-400" {...rest}>{children}</blockquote>
              ), props),
              img: (props) => wrapWithEditDelete(({ ...rest }) => (
                <img {...rest} className="rounded-lg shadow-md my-3 sm:my-4 max-w-full h-auto" alt={rest.alt || "Blog post image"} />
              ), props),
              table: (props) => wrapWithEditDelete(({ children, ...rest }) => (
                <div className="overflow-x-auto my-3 sm:my-4">
                  <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700 text-sm sm:text-base" {...rest}>{children}</table>
                </div>
              ), props),
              th: (props) => (
                <th className="px-4 sm:px-6 py-2 sm:py-3 bg-neutral-50 dark:bg-neutral-800 text-left text-xs font-medium text-neutral-500 dark:text-neutral-400 uppercase tracking-wider" {...props} />
              ),
              td: (props) => (
                <td className="px-4 sm:px-6 py-2 sm:py-3 whitespace-nowrap text-sm text-neutral-700 dark:text-neutral-300" {...props} />
              ),
              code: ({ node, inline, className, children, ...props }) => {
                const match = /language-(\w+)/.exec(className || "");
                const language = match ? match[1] : "";

                if (inline) {
                  return (
                    <code
                      className="bg-neutral-100 dark:bg-neutral-700 rounded-md px-1 py-0.5 text-sm text-neutral-800 dark:text-neutral-200"
                      {...props}
                    >
                      {children}
                    </code>
                  );
                }

                return wrapWithEditDelete(
                  ({ ...rest }) => (
                    <div className="relative">
                      <div className="absolute top-0 right-0 mt-2 mr-2 flex items-center space-x-2">
                        <span className="text-xs font-mono bg-primary-100 dark:bg-primary-900 text-primary-700 dark:text-primary-300 px-2 py-1 rounded">
                          {language}
                        </span>
                        <button
                          onClick={() =>
                            copyToClipboard(
                              String(children),
                              node.position?.start.line
                            )
                          }
                          className="text-neutral-400 hover:text-neutral-600 dark:text-neutral-500 dark:hover:text-neutral-300 transition-colors duration-200"
                        >
                          {copiedCode === node.position?.start.line ? (
                            <Check size={14} className="text-green-500" />
                          ) : (
                            <Copy size={14} />
                          )}
                        </button>
                      </div>
                      <SyntaxHighlighter
                        style={atomDark}
                        language={language}
                        PreTag="div"
                        className="rounded-md my-3 sm:my-4 pt-8 text-sm sm:text-base"
                        {...rest}
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    </div>
                  ),
                  { node, ...props }
                );
              },
            }}
          >
            {post}
          </ReactMarkdown>
        </div>
      </div>
    </motion.div>
  );
};

export default RenderPost;
