import React, { useState } from "react";
import RenderPost from "./RenderPost";
import { motion, AnimatePresence } from "framer-motion";
import { Type, Check, Copy, Plus, ChevronDown, Clipboard } from 'lucide-react';

export default function CreateMarkdown() {
    const [markdown, setMarkdown] = useState("");
    const [currentInput, setCurrentInput] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [codeLanguage, setCodeLanguage] = useState("javascript");
    const [tableData, setTableData] = useState([["", ""]]);
    const [iframeUrl, setIframeUrl] = useState("");
    const [copied, setCopied] = useState(false);
    const [copiedJson, setCopiedJson] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingEndIndex, setEditingEndIndex] = useState(null);

    const handleAddElement = () => {
        let newMarkdown = "";
        if (selectedType === "title") {
            newMarkdown = `\n# ${currentInput}\n`;
        } else if (selectedType === "subtitle") {
            newMarkdown = `\n## ${currentInput}\n`;
        } else if (selectedType === "subheading") {
            newMarkdown = `\n### ${currentInput}\n`;
        } else if (selectedType === "paragraph") {
            newMarkdown = `\n${currentInput}\n`;
        } else if (selectedType === "code") {
            newMarkdown = `\n\`\`\`${codeLanguage}\n${currentInput}\n\`\`\`\n`;
        } else if (selectedType === "image") {
            newMarkdown = `\n![Alt Text](${currentInput})\n`;
        } else if (selectedType === "list") {
            const items = currentInput.split(',').map(item => `- ${item.trim()}`).join('\n');
            newMarkdown = `\n${items}\n`;
        } else if (selectedType === "table") {
            const header = tableData[0].join(" | ");
            const separator = tableData[0].map(() => "---").join(" | ");
            const rows = tableData.slice(1).map((row) => row.join(" | ")).join("\n");
            newMarkdown = `\n| ${header} |\n| ${separator} |\n${rows ? `| ${rows} |` : ""}\n`;
        } else if (selectedType === "iframe") {
            newMarkdown = `\n<iframe width="560" height="315" src="${iframeUrl}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>\n`;
        }

        if (editingIndex !== null) {
            const markdownLines = markdown.split('\n');
            if (editingEndIndex !== null) {
                // Replace multiple lines
                markdownLines.splice(editingIndex, editingEndIndex - editingIndex + 1, newMarkdown.trim());
            } else {
                // Replace single line
                markdownLines[editingIndex] = newMarkdown.trim();
            }
            setMarkdown(markdownLines.join('\n'));
            setEditingIndex(null);
            setEditingEndIndex(null);
        } else {
            setMarkdown(markdown + newMarkdown);
        }

        resetForm();
    };

    const resetForm = () => {
        setCurrentInput("");
        setSelectedType("");
        setCodeLanguage("javascript");
        setTableData([["", ""]]);
        setIframeUrl("");
        setEditingIndex(null);
        setEditingEndIndex(null);
    };

    const handleEdit = (startLine, endLine) => {
        const markdownLines = markdown.split('\n');
        let contentToEdit;
        let typeToSet;
        let languageToSet = "javascript";

        if (endLine) {
            // Handling multi-line elements like code blocks
            contentToEdit = markdownLines.slice(startLine, endLine + 1).join('\n');

            // Extract code content and language for code blocks
            if (contentToEdit.startsWith('```')) {
                const lines = contentToEdit.split('\n');
                languageToSet = lines[0].replace('```', '').trim();
                contentToEdit = lines.slice(1, -1).join('\n');
                typeToSet = 'code';
                setCodeLanguage(languageToSet);
            }
        } else {
            contentToEdit = markdownLines[startLine];
        }

        // Determine the type of content being edited
        if (!typeToSet) {
            if (contentToEdit.startsWith('# ')) {
                typeToSet = 'title';
                contentToEdit = contentToEdit.replace('# ', '');
            } else if (contentToEdit.startsWith('## ')) {
                typeToSet = 'subtitle';
                contentToEdit = contentToEdit.replace('## ', '');
            } else if (contentToEdit.startsWith('### ')) {
                typeToSet = 'subheading';
                contentToEdit = contentToEdit.replace('### ', '');
            } else if (contentToEdit.startsWith('![')) {
                typeToSet = 'image';
                // Extract URL from markdown image syntax
                const urlMatch = contentToEdit.match(/$$(.*?)$$/);
                contentToEdit = urlMatch ? urlMatch[1] : '';
            } else if (contentToEdit.startsWith('- ')) {
                typeToSet = 'list';
                // Convert bullet points back to input format
                const listItems = markdownLines
                    .slice(startLine, endLine + 1)
                    .filter(line => line.trim().startsWith('- '))
                    .map(line => line.replace('- ', '').trim());
        
                // If there's only one item, don't add a comma
                contentToEdit = listItems.length > 1 ? listItems.join(', ') : listItems[0];
            } else if (contentToEdit.startsWith('|')) {
                typeToSet = 'table';
                // Parse table data
                const tableLines = contentToEdit.split('\n').filter(line => line.trim() && !line.includes('---'));
                const parsedData = tableLines.map(line =>
                    line.split('|')
                        .filter(cell => cell.trim())
                        .map(cell => cell.trim())
                );
                setTableData(parsedData);
                contentToEdit = '';
            } else if (contentToEdit.startsWith('<iframe')) {
                typeToSet = 'iframe';
                const srcMatch = contentToEdit.match(/src="([^"]+)"/);
                setIframeUrl(srcMatch ? srcMatch[1] : '');
                contentToEdit = '';
            } else {
                typeToSet = 'paragraph';
            }
        }

        setSelectedType(typeToSet);
        setCurrentInput(contentToEdit);
        setEditingIndex(startLine);
        setEditingEndIndex(endLine || startLine);
    };

    const handleDelete = (startLine, endLine) => {
        const markdownLines = markdown.split('\n');
        markdownLines.splice(startLine, (endLine ? endLine - startLine + 1 : 1));
        setMarkdown(markdownLines.join('\n'));
    };

    const handleTableChange = (rowIndex, colIndex, value) => {
        const updatedTable = [...tableData];
        updatedTable[rowIndex][colIndex] = value;
        setTableData(updatedTable);
    };

    const addTableRow = () => setTableData([...tableData, Array(tableData[0].length).fill("")]);
    const addTableColumn = () => setTableData(tableData.map(row => [...row, ""]));

    const copyMarkdownToClipboard = () => {
        navigator.clipboard.writeText(markdown).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const parseMarkdownToJson = () => {
        const lines = markdown.split("\n");
        const jsonResult = [];
        let currentBlock = null;

        lines.forEach((line) => {
            if (line.startsWith("# ")) {
                jsonResult.push({ title: line.replace("# ", "").trim() });
            } else if (line.startsWith("## ")) {
                jsonResult.push({ subTitle: line.replace("## ", "").trim() });
            } else if (line.startsWith("### ")) {
                jsonResult.push({ subHeading: line.replace("### ", "").trim() });
            } else if (line.startsWith("![Alt Text](")) {
                const url = line.match(/$$(.*?)$$/)?.[1];
                jsonResult.push({ image: url });
            } else if (line.startsWith("<iframe")) {
                const src = line.match(/src="([^"]+)"/)?.[1];
                jsonResult.push({ ytVideoUrl: src });
            } else if (line.startsWith("- ")) {
                jsonResult.push({ list: line.replace("- ", "").trim() });
            } else if (line.startsWith("|")) {
                if (!currentBlock || !currentBlock.table) {
                    currentBlock = { table: [] };
                    jsonResult.push(currentBlock);
                }
                currentBlock.table.push(line.split("|").slice(1, -1).map((cell) => cell.trim()));
            } else if (line.startsWith("```")) {
                if (currentBlock && currentBlock.codeSnippets) {
                    currentBlock = null; // Close current block
                } else {
                    currentBlock = { codeSnippets: [{ language: line.replace("```", "").trim(), code: "" }] };
                    jsonResult.push(currentBlock);
                }
            } else if (currentBlock && currentBlock.codeSnippets) {
                currentBlock.codeSnippets[0].code += `${line}\n`;
            } else if (line.trim()) {
                jsonResult.push({ content: line.trim() });
            }
        });

        return jsonResult;
    };
    const copyJsonToClipboard = () => {
        const json = parseMarkdownToJson();
        navigator.clipboard.writeText(JSON.stringify(json, null, 2)).then(() => {
            setCopiedJson(true);
            setTimeout(() => setCopiedJson(false), 2000);
        });
    };

    return (
        <div className="min-h-screen p-4 sm:p-8  dark:from-gray-800 dark:to-gray-900 text-gray-800 dark:text-gray-200">
            <motion.h1
                className="text-4xl font-bold mb-8 text-center text-primary-600 dark:text-primary-400"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Markdown Editor
            </motion.h1>

            <div className="flex flex-col">
                {/* Live Preview */}
                <motion.div
                    className="flex-1 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg mb-8"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <h2 className="text-2xl font-semibold mb-4 flex items-center">
                        <Type className="mr-2" /> Live Preview
                    </h2>
                    <div className="prose prose-blue dark:prose-invert max-w-none relative">
                        <RenderPost
                            post={markdown}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                        <motion.button
                            onClick={copyJsonToClipboard}
                            className="absolute top-4 left-4 p-2 bg-primary-500 text-white rounded-full hover:bg-primary-600 transition-colors duration-200"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            title="Copy JSON"
                        >
                            {copiedJson ? <Check size={20} /> : <Clipboard size={20} />}
                        </motion.button>

                        <motion.button
                            onClick={copyMarkdownToClipboard}
                            className="absolute top-4 left-16 p-2 bg-primary-500 text-white rounded-full hover:bg-primary-600 transition-colors duration-200"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            title="Copy Markdown"
                        >
                            {copied ? <Check size={20} /> : <Copy size={20} />}
                        </motion.button>
                    </div>
                </motion.div>

                {/* Input Section */}
                <motion.div
                    className="flex-1 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <h2 className="text-2xl font-semibold mb-4 flex items-center">
                        <Plus className="mr-2" /> Add Markdown Elements
                    </h2>

                    {/* Element Type Selector */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2">Select Element Type</label>
                        <div className="relative">
                            <select
                                value={selectedType}
                                onChange={(e) => setSelectedType(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md appearance-none bg-white dark:bg-gray-700 pr-10 focus:outline-none focus:ring-2 focus:ring-primary-500"
                            >
                                <option value="">Select type...</option>
                                <option value="title">Title</option>
                                <option value="subtitle">Sub Title</option>
                                <option value="subheading">Sub Heading</option>
                                <option value="paragraph">Paragraph</option>
                                <option value="code">Code</option>
                                <option value="image">Image</option>
                                <option value="list">List</option>
                                <option value="table">Table</option>
                                <option value="iframe">YouTube Iframe</option>
                            </select>
                            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                        </div>
                    </div>

                    {/* Input Forms for selected element type */}
                    <AnimatePresence mode="wait">
                        {selectedType && (
                            <motion.div
                                key={selectedType}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >
                                {selectedType === "code" && (
                                    <div className="space-y-2">
                                        <select
                                            value={codeLanguage}
                                            onChange={(e) => setCodeLanguage(e.target.value)}
                                            className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                        >
                                            <option value="javascript">JavaScript</option>
                                            <option value="python">Python</option>
                                            <option value="html">HTML</option>
                                            <option value="css">CSS</option>
                                            <option value="java">Java</option>
                                            <option value="typescript">TypeScript</option>
                                            <option value="jsx">JSX</option>
                                            <option value="tsx">TSX</option>
                                        </select>
                                        <textarea
                                            placeholder="Enter code content"
                                            value={currentInput}
                                            onChange={(e) => setCurrentInput(e.target.value)}
                                            className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500 font-mono"
                                            rows={8}
                                        />
                                    </div>
                                )}
                                {(selectedType === "title" || selectedType === "subtitle" || selectedType === "subheading") && (
                                    <input
                                        type="text"
                                        placeholder={`Enter ${selectedType} text`}
                                        value={currentInput}
                                        onChange={(e) => setCurrentInput(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                    />
                                )}
                                {selectedType === "paragraph" && (
                                    <textarea
                                        placeholder="Enter paragraph text"
                                        value={currentInput}
                                        onChange={(e) => setCurrentInput(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                        rows={4}
                                    />
                                )}
                                {selectedType === "image" && (
                                    <input
                                        type="text"
                                        placeholder="Enter image URL"
                                        value={currentInput}
                                        onChange={(e) => setCurrentInput(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                    />
                                )}
                                {selectedType === "list" && (
                                    <textarea
                                        placeholder="Enter list items (separate by commas)"
                                        value={currentInput}
                                        onChange={(e) => setCurrentInput(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                        rows={4}
                                    />
                                )}
                                {selectedType === "table" && (
                                    <div className="space-y-2">
                                        {tableData.map((row, rowIndex) => (
                                            <div key={rowIndex} className="flex gap-2">
                                                {row.map((cell, colIndex) => (
                                                    <input
                                                        key={colIndex}
                                                        type="text"
                                                        value={cell}
                                                        onChange={(e) => handleTableChange(rowIndex, colIndex, e.target.value)}
                                                        placeholder={`Row ${rowIndex + 1} Column ${colIndex + 1}`}
                                                        className="flex-1 px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                                    />
                                                ))}
                                            </div>
                                        ))}
                                        <div className="flex gap-2">
                                            <motion.button
                                                onClick={addTableRow}
                                                className="flex-1 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors duration-200"
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                Add Row
                                            </motion.button>
                                            <motion.button
                                                onClick={addTableColumn}
                                                className="flex-1 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors duration-200"
                                                whileHover={{ scale: 1.05 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                Add Column
                                            </motion.button>
                                        </div>
                                    </div>
                                )}
                                {selectedType === "iframe" && (
                                    <input
                                        type="text"
                                        placeholder="Enter YouTube embed URL"
                                        value={iframeUrl}
                                        onChange={(e) => setIframeUrl(e.target.value)}
                                        className="w-full px-4 py-2 border rounded-md dark:border-gray-600 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                    />
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <motion.button
                        onClick={handleAddElement}
                        className="mt-4 px-6 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600 transition-colors duration-200"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        disabled={!selectedType}
                    >
                        {editingIndex !== null ? 'Update Element' : 'Add Element'}
                    </motion.button>
                </motion.div>
            </div>
        </div>
    );
}

